import { get, isEmpty, omit } from 'lodash';
import { objectToQueryString, slugify } from './helper';
export function parseQueryString(url) {
	// Extract the query string from the URL if a full URL is provided
	const queryString = url.includes('?') ? url.split('?')[1] : url;

	// Split the query string into an array of key-value pair strings
	const pairs = queryString.split('&');

	// Initialize an empty object to hold the parameters
	const paramsObj = {};

	// Iterate over each key-value pair string
	pairs.forEach((pair) => {
		// Split each pair by '=' to get the key and value
		const [key, value] = pair.split('=');

		// Decode the key and value, and assign them to the object
		// Use `decodeURIComponent` to ensure proper decoding
		paramsObj[decodeURIComponent(key)] = decodeURIComponent(value || '');
	});

	return paramsObj;
}
export function replacePlaceholders(obj = {}, replacements) {
	// Function to apply replacements to the object
	function applyReplacements(currentObj = {}, replacementObj) {
		return Object.keys(currentObj).reduce((newObj, key) => {
			let value = currentObj[key];
			if (typeof value === 'string' && value.startsWith('{') && value.endsWith('}')) {
				// const placeholder = value.slice(1, -1); // Remove the curly braces
				const placeholder = value;
				if (replacementObj.hasOwnProperty(placeholder)) {
					value = replacementObj[placeholder];
				}
			}
			newObj[key] = value;
			return newObj;
		}, {});
	}

	// Check if replacements is an array or a single object
	if (Array.isArray(replacements)) {
		// Apply each replacement object in the array
		return replacements.reduce(applyReplacements, { ...obj });
	} else if (typeof replacements === 'object') {
		// Apply a single replacement object
		return applyReplacements(obj, replacements);
	} else {
		// If replacements is neither an object nor an array, return the original object
		return obj;
	}
}

const generateBlockLink = (data) => {
	const { block_view_all_link, block_data } = data;
	let link: any = '/';
	const children: { key: number; link: string; target?: string }[] = [];
	let bannerLink: string | undefined = '';
	const isArray = Array.isArray(block_data) && block_data.length > 0;
	switch (block_view_all_link) {
		case 'category':
			link = generateCategoryLink(data, children) || '/';
			break;
		case 'products':
			link = generateProductLinkForBlock(data, children) || '/';
			break;
		case 'brand':
			link = generateBrandLink(data, children) || '/';
			break;
		default:

			if (block_view_all_link) {
				const blockparams = objectToQueryString(data?.block_link_parameter || {});
				link = `/products?source=${block_view_all_link}${blockparams ? '&' + blockparams : ''}`;
			} else {
				link = '/';
			}

			const isObject = typeof block_data === 'object';
			if (isArray) {
				block_data.forEach((item, key) => {
					children.push(linkGenerator(item, key, data, true));
				});
				break;
			}
			if (isObject) {
				bannerLink = linkGenerator(block_data, 0, data, true)?.link || '';
			}
			break;
	}
	return { link, children, bannerLink };
};

export default generateBlockLink;

const generateCategoryLink = (data, children) => {
	const { block_view_all_link, block_link_parameter, block_data, block_type, block } = data;
	const slug = slugify(block);

	// Return a link directly if block_data is not an array, is empty, or matches block_view_all_link
	if (!Array.isArray(block_data) || !block_data.length || block_type === block_view_all_link) {
		const category = get(block_link_parameter, 'tax_category');
		const id = get(block_link_parameter, 'id');
		const queryString = objectToQueryString(omit(block_link_parameter, 'tax_category', 'id'));
		return id
			? `/category/${category}/${id}/${slug}${queryString ? '?' + queryString : ''}`
			: `/category/${category}${queryString ? '?' + queryString : ''}`;
	}

	// Process each item in block_data
	block_data.forEach((item, key) => {
		const title = slugify(item?.title);
		let parentId = get(block_link_parameter, 'tax_category');

		// Update parentId if available in item's link_parameter
		if (get(item.link_parameter, 'tax_category')) {
			parentId = get(item.link_parameter, 'tax_category');
		}

		// Generate link for each item
		const id = get(item.link_parameter, 'id');
		const itemQueryString = objectToQueryString(
			omit(
				{
					...block_link_parameter,
					...item.link_parameter
				},
				'tax_category',
				'id'
			)
		);
		const link =
			item.link === block_view_all_link || isEmpty(item.link)
				? `/${block_view_all_link}/${parentId}${id ? `/${id}/${slugify(item?.title)}` : ''}${itemQueryString ? `?${itemQueryString}` : ''
				}`
				: linkGenerator(item, key, data, true);

		children.push({
			key,
			link,
			isValid: true
		});
	});

	const id = get(block_link_parameter, 'id');

	const category = get(block_link_parameter, 'tax_category');
	// Return the final link
	const queryString = objectToQueryString(omit(block_link_parameter, 'tax_category', 'id'));
	return id
		? `/category/${category}/${id}${queryString ? '?' + queryString : ''}`
		: `/category/${category}${queryString ? '?' + queryString : ''}`;
};

const generateProductLinkForBlock = (data, children) => {
	// Destructure properties from data object
	const { block_view_all_link, block_link_parameter, block_data, block_type, block_machine_name } = data;

	// Retrieve the 'call' value from block_link_parameter
	const pathValue = get(block_link_parameter, 'call');
	const params = replacePlaceholders(block_link_parameter || {}, data?.extra || {});

	// Initialize link variable
	let link = '';

	// Generate the base link
	if (pathValue === 'block' && block_machine_name) {
		link = `/${block_view_all_link}/?source=${block_machine_name}&${objectToQueryString(omit(params, 'call'))}`;
	} else {
		link = `/${block_view_all_link}/?source=${block_machine_name}&${objectToQueryString(omit(params, 'call'))}`;
	}

	// Process block_data for certain block types
	if (['actions', 'image'].includes(block_type)) {
		const isArray = Array.isArray(block_data) && block_data.length > 0;
		if (isArray) {
			block_data.forEach((item, key) => {
				if (block_view_all_link === item.link || isEmpty(item.link)) {
					const queryString = objectToQueryString({
						source: block_machine_name,
						campaign: slugify(item.uuid)
					});
					children.push({
						key,
						link: `${block_view_all_link}/${queryString ? '?' + queryString : ''}`,
						isValid: true
					});
				} else {
					children.push(linkGenerator(item, key, data, true));
				}
			});
		}
	}

	// Return the generated link
	return link;
};

const generateBrandLink = (data, children) => {
	const { block_data, block_view_all_link, block_type } = data;
	let link = '';
	link = `/${block_view_all_link}`;

	if (block_type === 'function') {
		block_data.forEach((item, key) => {
			const id = item.pb_id;
			const slug = slugify(item?.pb_name);
			children.push({
				key: key,
				link: `/${block_view_all_link}/${id}/${slug}`
			});
		});
		return link;
	}
	const isArray = Array.isArray(block_data) && block_data.length > 0;

	if (isArray) {
		block_data.forEach((item, key) => {
			if (block_view_all_link !== item.link) {
				children.push(linkGenerator(item, key, data, true));
				return link;
			}

			const id = get(item.link_parameter, 'id');
			const extraParams = omit(item.link_parameter, 'id');
			const queryString = objectToQueryString({
				...extraParams
			});
			const slug = slugify(item.title);
			children.push({
				key: key,
				link: `/${block_view_all_link}/${id}/${slug}${queryString ? '?' + queryString : ''}`,
				isValid: true
			});
		});
	}
	return link;
};

const linkGenerator = (item, key, data, debug = false) => {
	let params = replacePlaceholders(item.link_parameter || {}, data?.extra || {});
	const source = get(data, 'block_machine_name');
	const queryString = objectToQueryString({
		source: source,
		campaign: slugify(item.uuid),
		...params
	});
	switch (item.link) {
		case 'Whatsapp':
			return {
				isValid: true,
				key: key,
				link: `https://api.whatsapp.com/send/?phone=${item.alt}&text=I%27m+interested+to+order+product&type=phone_number&app_absent=0`,
				target: '_blank'
			};
		case 'Prescriptions':
			return {
				isValid: true,
				key: key,
				link: '/account/prescriptions',
				target: '_self'
			};

		case 'PharmacyRegistration':
			return {
				isValid: true,
				key: key,
				link: '/pharmacy-register',
				target: '_self'
			};

		case 'Call To Order':
			return {
				isValid: true,
				key: key,
				link: `tel:${item.alt}`,
				target: '_blank'
			};

		case 'Refer':
			return {
				isValid: true,
				key: key,
				link: '/account/referral',
				target: '_self'
			};

		case 'products':
			return {
				isValid: true,
				key: key,
				link: `/products${queryString ? '?' + queryString : ''}`,
				target: '_self'
			};

		case 'category':
			const pathValue = get(params, 'tax_category');
			const id = get(params, 'id');
			const categoryQueryString = objectToQueryString(omit(item.link_parameter, 'tax_category', 'id'));
			let link = `/category/${pathValue}${categoryQueryString ? '?' + categoryQueryString : ''}`;
			if (id) {
				link = `/category/${pathValue}/${id}${categoryQueryString ? '?' + categoryQueryString : ''}`;
			}

			return {
				isValid: true,
				key: key,
				link: link,
				target: '_self'
			};

		case 'brand':
			const _brand_id = get(item.link_parameter, 'id');
			params = omit(item.link_parameter, 'id');
			const brandQueryString = objectToQueryString(params);
			return {
				isValid: true,
				key: key,
				link: `/brand/${_brand_id}${brandQueryString ? '?' + brandQueryString : ''}`,
				target: '_self'
			};

		case 'blog':
			const blog_type = get(params, 'blog_type');
			const blog_id = get(params, 'blog_id');
			const blogQueryString = objectToQueryString(omit(item.link_parameter, 'blog_id', 'blog_type'));
			let blogLink = '';
			if (blog_id) {
				blogLink = `/blog/${blog_type}/${blog_id}${blogQueryString ? '?' + blogQueryString : ''}`;
			} else {
				blogLink = `/blog/${blog_type}${blogQueryString ? '?' + blogQueryString : ''}`;
			}
			return {
				isValid: true,
				key: key,
				link: blogLink,
				target: '_self'
			};
		default:
			if (item?.link?.startsWith('http') || item?.link?.startsWith('https') || item?.link?.startsWith('www')) {
				return {
					isValid: true,
					key: key,
					link: item.link,
					target: '_blank'
				};
			}

			return {
				isValid: isEmpty(item?.link) ? false : true,
				key: key,
				link: item.link,
				target: '_self'
			};
	}
};
